<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
      V prípade, že si nepamätáš svoje heslo, tak zadaj nižšie svoju emailovú adresu (ktorú máš uvedenú vo svojom profile) a klikni na požiadať o nové heslo.
        <br />Na email Ti zašleme pokyny o tom, čo máš robiť. Nezabudni skontrolovať spam vo svojej schránke.
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-text-field
          v-model="email"
          label="Email"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-btn
          color="primary"
          :disabled="loading"
          @click="resetPassword()"
        >Požiadať o nové heslo</v-btn>
      </v-col>
    </v-row>
    <v-alert v-model="alert" dark color="success">V prípade, že si napísal správne emailovú adresu, tak Ti tam prídu pokyny na zmenu hesla.</v-alert>
  </v-container>
</template>

<script>
import { APIService } from "@/http/APIService.js";
const apiService = new APIService();


export default {
  props: {
    source: String,
  },
  data() {
    return {
      email: "",
      loading: false,
      alert: false,
    };
  },


  computed: {
  },

  components: {
  },


  methods: {
    resetPassword() {
      this.loading = true;
      apiService.resetPassword(this.email).then(() => {
        this.loading = false;
        this.alert = true;
      });
    },
  },
};
</script>